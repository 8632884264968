<template>
  <div class="main-body">

    <div class="table-search">
      <a-form :model="formState" layout="inline" autocomplete="off" @submit="onLoadList">
        <a-form-item label="标题">
          <a-input v-model:value="formState.keyword" placeholder="请输入标题关键字" />
        </a-form-item>
        <a-form-item label="删除时间">
          <a-range-picker format="YYYY-MM-DD" v-model:value="formState.betweenTime" />
        </a-form-item>
        <a-form-item class="search-btn">
          <a-button type="primary" html-type="submit">搜索</a-button>
        </a-form-item>
        <a-form-item>
          <a-button @click="resetSearchForm">重置</a-button>
        </a-form-item>
      </a-form>
    </div>
    <div class="table-operator">
      <a-row>
        <a-col :span="20" class="lin-left">
          <a-button type="primary" :disabled="!hasSelected" :loading="loading" @click="batch('renew')" class="margin-right15" size="small">
            还原
          </a-button>
          <a-button type="primary" danger :disabled="!hasSelected" :loading="loading" @click="batch('delete')" class="margin-right15" size="small">
            彻底删除
          </a-button>
          <span>
            <template v-if="hasSelected">
              {{ `您选择了 ${selectedRowKeys.length} 项` }}
            </template>
          </span>
        </a-col>
        <a-col :span="4" class="lin-right">
          <a-button type="primary" danger @click="emptyRecycle">清空回收站</a-button>
        </a-col>
      </a-row>
    </div>
    <a-table rowKey="article_id" :row-selection="{
              selectedRowKeys: selectedRowKeys,
              onChange: onSelectChange,
            }" :pagination="pagination" :columns="columns" :data-source="formState.articleList">
      <template #bodyCell="{ column,record }">
        <template v-if="column.dataIndex === 'action'">
          <span>
            <a-button type="link" @click="renewHandleSubmit(record.article_id)">还原</a-button>
            <a-button type="link" danger @click="forceDeleteHandleSubmit(record.article_id)">删除</a-button>
          </span>
        </template>
      </template>
    </a-table>
  </div>
</template>
<script>
import { defineComponent, reactive, computed, toRefs, onMounted } from "vue";
import { message, Modal } from "ant-design-vue";
import * as Api from "@/views/document/api";
export default defineComponent({
  setup() {
    const formState = reactive({
      keyword: "",
      betweenTime: [],
      page: 1,
      articleList: [],

      total: 0,
      current_page: 0,
      per_page: 0,
    });

    // 根据条件获取列表数据
    const onLoadList = (page) => {
      Api.getArticleRecycleList({
        page: page?.current,
        keyword: formState.keyword,
        betweenTime: formState.betweenTime,
      }).then((res) => {
        formState.articleList = res.data.data;
        formState.total = res.data.total;
        formState.per_page = res.data.per_page;
        formState.current_page = res.data.current_page;
      });
    };

    // 列表分页数据
    const pagination = computed(() => ({
      total: formState.total,
      current: formState.current_page,
      pageSize: formState.per_page,
    }));

    // 重置表单数据
    const resetSearchForm = () => {
      formState.keyword = "";
      formState.betweenTime = [];
      state.selectedRowKeys = [];
      onLoadList();
    };

    const state = reactive({
      selectedRowKeys: [],
      loading: false,
    });
    const hasSelected = computed(() => state.selectedRowKeys.length > 0);

    const onSelectChange = (selectedRowKeys) => {
      state.selectedRowKeys = selectedRowKeys;
    };

    //批量操作
    const batch = (event) => {
      console.log(event);
      let keys = state.selectedRowKeys;
      console.log(keys);
      switch (event) {
        case "renew":
          // 批量下架articleforceDeleteByIds
          renewHandleSubmit(keys);
          break;
        case "delete":
          // 批量删除
          forceDeleteHandleSubmit(keys);
          break;
        default:
          break;
      }

      state.selectedRowKeys = [];
    };

    // 还原
    const renewHandleSubmit = (data) => {
      Modal.confirm({
        title: "确定还原这些文档吗，还原后会放入草稿箱！",
        okText: "确定",
        cancelText: "取消",
        onOk() {
          Api.articleRenewByIds({
            ids: data,
          }).then((res) => {
            if (res.status == 200) {
              // 刷新列表
              onLoadList();
              message.success(res.message);
            } else {
              message.error(res.message);
            }
          });
        },
      });
    };

    //删除文档提示框
    const forceDeleteHandleSubmit = (data) => {
      Modal.confirm({
        title: "彻底删除后将无法找回！",
        okText: "确定",
        okType: "danger",
        cancelText: "取消",
        onOk() {
          Api.articleforceDeleteByIds({
            ids: data,
          }).then((res) => {
            if (res.status == 200) {
              // 刷新列表
              onLoadList();
              message.success(res.message);
            } else {
              message.error(res.message);
            }
          });
        },
      });
    };

    const emptyRecycle = () => {
      const recycleModal = Modal.confirm({
        title: "确定清空回收站吗？清空后将无法恢复，3秒后再确认。",
        okText: "确定",
        okType: "danger",
        okButtonProps: {
          disabled: true,
        },
        cancelText: "取消",
        onOk() {
          Api.articleEmptyRecycle().then((res) => {
            if (res.status == 200) {
              // 刷新列表
              onLoadList();
              message.success(res.message);
            } else {
              message.error(res.message);
            }
          });
        },
      });

      setTimeout(function () {
        recycleModal.update({ okButtonProps: { disabled: false } });
      }, 3000);
    };

    onMounted(() => {
      // 获取列表数据
      onLoadList();
    });
    return {
      formState,
      onLoadList,
      pagination,
      resetSearchForm,
      batch,
      hasSelected,
      ...toRefs(state),
      onSelectChange,
      renewHandleSubmit,
      forceDeleteHandleSubmit,
      emptyRecycle,
      data: [],
      columns: [
        {
          title: "ID",
          dataIndex: "article_id",
          width: "5%",
        },
        {
          title: "标题",
          dataIndex: "title",
          width: "60%",
        },
        {
          title: "删除时间",
          dataIndex: "update_time",
          width: "20%",
        },
        {
          title: "操作",
          dataIndex: "action",
          width: "15%",
        },
      ],
    };
  },
});
</script>